<template>
  <div>
    <Loading v-if="loading" />

    <Error v-if="error && !loading">
      {{ error }}
    </Error>

    <b-table v-if="items && items.length" :items="items" :fields="fields" striped dark>
      <template #cell(created)="row">
        {{ row.item.created | relativeDate }}
      </template>
      <template #cell(actions)="row">
        <div class="d-flex justify-content-end">
          <b-button size="sm" variant="warning" :to="row.item.route"> Load Report </b-button>
        </div>
      </template>
    </b-table>

    <div v-if="!loading && !error && !(items && items.length)" class="text-center text-muted mt-5 mb-5">
      No reports available.
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { mapGetters } from 'vuex'

import { searchReports } from '@/api/reports.js'
import Error from '@/components/generic/Error.vue'
import Loading from '@/components/generic/Loading.vue'
import { timestamp_2_date } from '@/filters/dates.js'
import relativeDate from '@/filters/relativeDate.js'
import { deepFreeze } from '@/helper.js'

export default {
  components: { Error, Loading },
  data() {
    return {
      loading: false,
      error: null,
      fields: [
        { key: 'idx', label: '' },
        { key: 'name', label: 'Report Name', sortable: true },
        { key: 'created', label: 'Date Created', sortable: true },
        { key: 'actions', label: '' },
      ],
      items: null,
    }
  },
  filters: {
    timestamp_2_date,
    relativeDate,
  },
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
    }),
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData(this.$route.params)
      },
      { immediate: true }
    )
  },
  methods: {
    async fetchData({ team_id, map_id }) {
      this.error = null
      this.items = []
      this.loading = true
      try {
        const reports = await searchReports({ team_id, map_id })
        this.items = deepFreeze(
          reports.map(report => ({
            ...report,
            route: {
              path: `/map/${this.getMapById(report.map_id).name}/${report.team_id}`,
              query: { report: report.id, team: report.team_id },
            },
          }))
        )
      } catch (error) {
        this.error = error.response?.data.detail || error
        Sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
