<template>
  <div class="bg-danger text-white p-2">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Error',
}
</script>
