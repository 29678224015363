import axios from '@/axios.js'

/**
 * 1.0.0 - first version
 * 1.0.1 - add .player: 'youtube'|'twitch'|'vod'
 * 2.0.0 - matches is a list of match ids
 * 2.1.0 - eco filter can be set for both teams
 * 2.1.1 - remove selectedRound - it is already represented in selected.rounds
 * 2.1.2 - add .teamCompositions: Record<teamCompositionId: string, Array<boolean>>
 * @type {string}
 */
export const REPORT_VERSION = '2.1.0'

/**
 * @param {string} report_id
 * @param cancelToken
 * @return {Promise<API_TEAM_REPORT>}
 */
export async function getReportDetail(report_id, team_id, { cancelToken } = {}) {
  const response = await axios.get(`/val/v3/esport/teams/${encodeURIComponent(team_id)}/reports/${report_id}`, {
    cancelToken,
  })
  return response.data
}

/**
 *
 * @param {string} name
 * @param {string} team_id
 * @param {string} map_id
 * @param {any} data
 * @return {Promise<API_TEAM_REPORT_SHORT>}
 */
export async function createReport({ name, team_id, map, body }) {
  const response = await axios.post(`/val/v3/esport/teams/${encodeURIComponent(team_id)}/reports`, { name, map, body })
  return response.data
}

/**
 *
 * @param {string} team_id
 * @param {string} map_id
 * @param {number} limit
 * @param {number} offset
 * @param cancelToken
 * @return {Promise<API_TEAM_REPORT_SEARCH_RESULT[]>}
 */
export async function searchReports({ team_id, map_id, limit = 100, offset = 0 }, { cancelToken } = {}) {
  const response = await axios.get(`/val/v3/esport/teams/${encodeURIComponent(team_id)}/reports`, {
    cancelToken,
    params: {
      map: map_id,
      limit,
      offset,
    },
  })
  return response.data.reports.map((report, index) => {
    const idx = offset + 1 + index
    return {
      id: report.id || idx,
      idx,
      name: report.name || `Unnamed report ${idx}`,
      created: report.created,
      map_id: report.map || map_id,
      team_id: report.team || team_id,
    }
  })
}
